<template>
  <div class="columns">
    <div class="column is-2">
      <aside class="sidebar">
        <p class="menu-label">
          管理员控制台
        </p>
        <ul class="menu-list">
          <li>
            <ul>
              <li>
                <a href="#">监控台</a>
              </li>
            </ul></li>
          <li>
            <p class="menu-p">图片相关</p>
            <ul>
              <li>
                <router-link to="/audit/auditIMG">
                  图片审核
                </router-link>
              </li>
              <li>
                <router-link to="/admin/controlImage/classify">
                  分类管理
                </router-link>
              </li>
              <li><a>图片增删</a></li>
            </ul>
          </li>
        </ul>
        <a role="button" class="back-to-top">
          <span class="icon is-small">
            <i
              class="mdi mdi-arrow-expand-up">
            </i></span>
          <span>Back to top</span>
        </a>
      </aside>
    </div>
    <div class="column is-10">
      <router-view>
      </router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "adminControlImage"
}
</script>

<style scoped>

</style>
